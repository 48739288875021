.container.container {
  display: flex;
  flex-direction: column;
  max-width: 425px;
  padding: 35px 35px 0;
}

.title {
  margin: 0 0 30px;
  font-size: 18px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
